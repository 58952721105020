<div class="container" id="menu" [class.active]="active">
  <div class="content">
    <div class="brand">
      <a routerLink="/"> <img class="logo" [class.active]="active" src="assets/headerlogo.gif" alt="logo" height="73" /> </a>
    </div>
    <nav>
      <ul class="list" id="button" [class.active]="active">
        <li class="menu-item"><a routerLinkActive="active" routerLink="/about" class="pure-menu-link">&#x3008;About&#x3009;</a></li>
        <li class="menu-item"><a routerLinkActive="active" routerLink="/blog" class="pure-menu-link">&#x3008;Blog&#x3009;</a></li>
        <li class="menu-item"><a routerLinkActive="active" routerLink="/projects" class="pure-menu-link">&#x3008;Projects&#x3009;</a></li>
      </ul>
    </nav>

    <div class="burger-menu" [class.active]="active" (click)="onBurgerClicked()">
      <div class="burger-inner">
        <a href="#"><span></span></a>
      </div>
      <a class="custom-toggle" id="toggle">
        <s class="bar"></s>
        <s class="bar"></s>
        <s class="bar"></s>
      </a>
    </div>
  </div>
</div>
