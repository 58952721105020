<!-- First Container -->
<div class="container">
  <div class="projectBox">
    <h1 class="afirst-head">What I've been up to. <img class="icons" src="assets/icon4.png" alt="face" width="30" height="30" /></h1>
    <div class="flex">
      <a [routerLink]="['/project/getsby']">
        <img src="assets/images.png" alt="getsby" width="250" height="250" class="ProjectImg" />
      </a>
      <a [routerLink]="['/project/tedx']">
        <img src="assets/ted.png" alt="ted" width="250" height="250" class="ProjectImg" />
      </a>
      <a [routerLink]="['/project/uniportal']">
        <img src="assets/uniPortal_uni_wien_logo.jpg" alt="uniportal" width="250" height="250" class="ProjectImg" />
      </a>
      <a [routerLink]="['/project/thesis']">
        <img src="assets/thesis.PNG" alt="thesis" width="250" height="250" class="ProjectImg" />
      </a>
    </div>
  </div>
</div>
