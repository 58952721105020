import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input() init: boolean;
  @Output() opened = new EventEmitter<any>();
  @ViewChild('#menu', { static: true }) menu;

  constructor() {}

  ngAfterViewInit() {
    window.addEventListener('resize', () => {
      return this.active ? this.onBurgerClicked() : undefined;
    }),
      //body
      document.getElementById('button').addEventListener('click', () => {
        return this.active ? this.onBurgerClicked() : undefined;
      });
  }

  active = false;

  ngOnInit() {
    this.active = this.init || false;
  }

  onBurgerClicked() {
    this.active = !this.active;
    this.opened.emit();
  }
}
