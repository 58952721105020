<!-- First Container -->
<div class="container">
  <div class="projectBox">
    <h1 class="afirst-head">What I've been up to. <img src="assets/icon4.png" alt="face" width="30" height="30" /></h1>
    <div class="flex">
      <img [src]="job.img" alt="project thumbnail" width="400" height="400" class="ProjectImg" />

      <div class="textinfo">
        <h1 class="jobHead">
          {{ job.job }}
        </h1>

          <p class="afirst-subhead">
            {{ job.text }}
            <br /><br />
          </p>

          <div  *ngIf="job.website != null">
            For more info visit: <a [href]="job.website" target="_blank" class="icon-block"> {{ job.placeholder }}</a>
          </div>
      </div>
    </div>
  </div>
</div>
