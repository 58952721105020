import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../app/shared/services/content.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  page: Object;

  constructor(private route: ActivatedRoute,
    private contentService: ContentService) { }

  ngOnInit() {
    const pageData = this.route.snapshot.data['page'];
    this.page = this.contentService.pages[pageData];;
  }

}
