  <div class="container">
    <div class="aboutBox">
      <h1 class="afirst-head">About{{ ' ' }}<img class="icons" src="assets/icon1.png" alt="face" width="25" height="25" /></h1>
      <p class="afirst-subhead">
        Hi, my name is Stefan. I am currently working as a developer at <a href="https://bitfly.at">bitfly</a>. We build solutions for cryptocurrency
        miners such as <a href="https://ethermine.org">ethermine</a>. If you wish to contact me my preferred e-mail addresses is
        <a href="mailto:stefan@starflinger.eu">stefan@starflinger.eu</a>. My latest academic CV is available
        <a href="assets/CV.pdf" target="_blank"> here. </a>
      </p>

      <h1 class="afirst-head">Academical{{ ' ' }}<img class="icons" src="assets/icon2.png" alt="face" width="20" height="25" /></h1>
      <p class="afirst-subhead">
        I have a master's degree in Business Informatics. My thesis deals with bilateral negotiations between cloud providers and consumers. In
        essence, I looked into creating a decentralized market place for the procurement of cloud resources. I created a
        <a href="https://github.com/qu0b/conviction">proof of concept</a> that provides improved provenance and integrity over previous approaches.
      </p>
      <p class="afirst-subhead">
        After successfully completing my Master Degree I spent six months as a research assistant at the University of Vienna researching interactive
        and visual analysis of large networks.
      </p>
      <h1 class="afirst-head">
        Have a question or want to work together?{{ ' ' }}<img class="icons" src="assets/icon3.png" alt="face" width="25" height="25" />
      </h1>
      <p class="afirst-subhead">
        Feel free to reach out to me if you have any questions. For encrypted communication, please use my
        <a href="https://starflinger.eu/assets/Starflinger_GPG.asc">public GPG key</a>.
      </p>
    </div>
</div>
