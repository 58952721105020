<div class="footer">
    <p class="copyright">© 2020 Stefan Starflinger. All rights reserved.</p>

    <div id="navcontainer">
      <ul id="navlist">
        <li><a routerLink="/">Home </a></li>
        <li><a routerLink="/about">About</a></li>
        <li><a routerLink="/blog">Blog</a></li>
        <li><a routerLink="/projects">Projects</a></li>
        <li><a routerLink="/impressum">Impressum</a></li>
      </ul>
    </div>

    <!-- Social Media Icons -->

    <a href="https://medium.com/@st3f4n.s" target="_blank" rel="noopener" class="icon-block">
      <i class="fab fa-medium-m fa-5x"></i>
    </a>

    <a href="https://www.linkedin.com/in/stefan-starflinger" rel="noopener" target="_blank" class="icon-block-linked">
      <i class="fab fa-linkedin-in"></i>
    </a>

    <a href="https://twitter.com/stefan_star" target="_blank" rel="noopener" class="icon-block-twitter">
      <i class="fab fa-twitter"></i>
    </a>
</div>
