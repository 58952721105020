<div class="container">
  <div class="blogBox">
    <div class="blog-wrapper">
      <div class="blog-card">
        <div class="card-img">
          <img src="assets/blog1.PNG" />
        </div>

        <div class="card-text">
          <h1>Subscriptions for React-Native with Appsync and Lambda Resolvers</h1>
          <p style="color: grey;">
            At getsby we are developing an app that lets you order from your seat in a restaurant. To accomplish this, we are creating point of sale (POS) integrations that support
            real time data.
          </p>
        </div>
        <a href="https://medium.com/@st3f4n.s/subscriptions-for-react-native-with-appsync-and-lambda-resolvers-d53349a0755b" target="_blank">
          <div class="read-more">Read More</div>
        </a>
      </div>
    </div>
  </div>
</div>
