export const jobs = [
  {
    key: 'getsby',
    text:
      'At getsby we developed an app that lets users order and pay for their food directly from their seat. I worked at getsby during its founding days as Head of Development. My main focus was on developing the react-native mobile application.',
    website: 'https://gets.by',
    placeholder: 'getsby',
    job: 'getsby',
    img: 'assets/images.png',
  },
  {
    key: 'tedx',
    text:
      'I volunteered for about half a year together with an amazing team. We planned and held a TEDx conference in Vienna. We had some amazing speakers and the event was a complete success',
    website: 'http://tedxdonauinsel.at/imaginaction',
    job: 'TEDxDonauinsel',
    placeholder: 'ImaginAction',
    img: 'assets/ted.png',
  },
  {
    key: 'uniportal',
    text:
      'Uniportal is a white label university application that I developed during my time as a student since there were a lot of missing features that our university platform did not provide.',
    website: 'https://uniportal-app.at',
    job: 'Uniportal',
    placeholder: 'Uniportal',
    img: 'assets/uniPortal_uni_wien_logo.jpg',
  },
  {
    key: 'thesis',
    text: `The increase in the supply of on-demand computing resources and the ever-growing number of
    internet capable devices calls for a new approach in provisioning cloud computing resources.
    Through trustless multi-round bilateral negotiation, we present an alternative to the current
    ’off the shelves’ approach. Much research has gone into Service Level Agreements (SLAs),
    from how they are defined, negotiated, and monitored to how they evolve. However, seeing as
    how trust is a necessity for SLA negotiations, researchers have not focused on ways to remove
    the need for trust. We demonstrate that an agreement between a consumer and a provider
    can be reached, without either having to trust the other. We spotlight a method, which lets
    consumers and providers negotiate for cloud resources. Our focus lies on storing negotiation
    offers and the resulting agreement in a Blockchain. That way, agreements are tamper proof,
    and their provenance is evident. This work opens the doors for integrating smart contracts
    into the negotiation and agreement process.`,
    job: 'My Master Thesis',
    img: 'assets/thesis.PNG',
    placeholder: 'Thesis',
    website: '/assets/thesis.pdf',
  },
];
