<div class="container">
  <div class="content">
    <h1>Who is more Tan?</h1>
    <h2>select an image to see the result!</h2>
    <div class="me">
      <img src="assets/me.png" alt="">
    </div>
    <div class="other">
      <img [src]="imgURL" alt="" *ngIf="imgURL">
    </div>
      <input #file type="file" accept="image/*" (change)="preview(file.files)">
      <button *ngIf="imgURL" (click)="toggleScore()">See Score</button>
  </div>

</div>
<div *ngIf="showScore" class="modal">
  <div class="modal-content">
    <h3>You Lose, stefan is more tan! ;)</h3>
  </div>
</div>
