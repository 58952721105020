import { Component, OnInit } from '@angular/core';
import { jobs } from 'src/app/jobs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-jobinfo',
  templateUrl: './jobinfo.component.html',
  styleUrls: ['./jobinfo.component.scss'],
})
export class JobinfoComponent implements OnInit {
  job;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    console.log('job info component');

    this.route.paramMap.subscribe((params) => {
      const project = params['params'].project;
      this.job = jobs.filter((job) => job.key === project)[0];
    });
  }
}
