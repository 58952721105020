import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from '../pages/about/about.component';
import { BlogComponent } from '../pages/blog/blog.component';
import { ImpressumComponent } from '../pages/impressum/impressum.component';
import { ProjectsComponent } from '../pages/projects/projects.component';
import { HomeComponent } from '../pages/home/home.component';
import { ContentService } from './shared/services/content.service';
import { FooterComponent } from '../components/footer/footer.component';
import { MenuComponent } from '../components/menu/menu.component';
import { JobinfoComponent } from '../pages/jobinfo/jobinfo.component';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TanComponent } from '../pages/tan/tan.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    BlogComponent,
    ImpressumComponent,
    ProjectsComponent,
    HomeComponent,
    FooterComponent,
    MenuComponent,
    JobinfoComponent,
    TanComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    environment.production ? ServiceWorkerModule.register('ngsw-worker.js') : [],
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [ContentService],
  bootstrap: [AppComponent],
})
export class AppModule {}
