import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../pages/home/home.component';
import { BlogComponent } from '../pages/blog/blog.component';
import { ImpressumComponent } from '../pages/impressum/impressum.component';
import { ProjectsComponent } from '../pages/projects/projects.component';
import { AboutComponent } from '../pages/about/about.component';
import { JobinfoComponent } from '../pages/jobinfo/jobinfo.component';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  {
    path: '',
    component: HomeComponent,
    data: {
      page: 'home',
    },
  },
  {
    path: 'blog',
    component: BlogComponent,
    data: {
      page: 'blog',
    },
  },
  {
    path: 'impressum',
    component: ImpressumComponent,
    data: {
      page: 'impressum',
    },
  },
  {
    path: 'about',
    component: AboutComponent,
    data: {
      page: 'about',
    },
  },
  {
    path: 'projects',
    component: ProjectsComponent,
    data: {
      page: 'projects',
    },
  },
  {
    path: 'project/:project',
    component: JobinfoComponent,
    data: {
      page: 'project',
    },
  },
  // {
  //   path: 'tan',
  //   component: TanComponent,
  //   data: {
  //     page: 'project',
  //   },
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
